<template>
    <v-card light flat class="pa-4">
        <v-progress-linear class="my-3" indeterminate v-if="loading"></v-progress-linear>
        <div class="d-flex flex-column flex-md-row mx-1">
            <v-text-field label="Type your question here" ref="searchfield" v-model="keywords" v-on:keyup="performSearch" filled autofocus></v-text-field>
            <!-- <v-btn class="mx-2" x-large depressed color="primary" dark right @click="searchResults"> Search<v-icon small right>fas fa-search</v-icon>
            </v-btn>-->
        </div>
        <div v-if="search_results.pages.data.length > 0" class="quick-results">
            <div class="q-subtitle pt-5">
                <h5>Quick Results</h5>
            </div>
            <v-list dense two-line>
                <v-list-item @click="loadPage(result)" v-for="(result, k) in search_results.pages.data" :key="k">
                    <v-list-item-content>
                        <v-list-item-subtitle>{{ result.category_name }}</v-list-item-subtitle>
                        <v-list-item-title class="q_blue_1--text"><strong>{{ result.title }}</strong></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-icon small v-if="result.external_url && result.external_url != 'internal'">fas fa-external-link-alt</v-icon>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
        </div>
    </v-card>
</template>
<script>
import QuilityAPI from "@/store/API/QuilityAPI.js";
import { debounce } from "vue-debounce";
import SearchResult from "@/store/Models/SearchResult";

export default {
    props: {},
    data: function() {
        return {
            keywords: null,
            search_results: {
                pages: {
                    data: [],
                    meta: {},
                },
            },
        };
    },
    computed: {
        search_support: {
            set(v) {
                this.$store.commit("updateShowSearch", v);
            },
            get() {
                return this.$store.state.show_search;
            },
        },
        /*'search_results': function() {
                return SearchResult.all()
            },*/
        loading: function() {
            return this.$store.state.entities.search_result.loading;
        },
    },
    methods: {
        loadPage: function(result) {
            if (result.external_url && result.external_url != "internal") {
                window.open(
                    result.external_url,
                    "_blank" // <- This is what makes it open in a new window.
                );
            } else {
                this.$router.push("/page/" + result.slug);
            }
            this.search_support = false;
        },
        loadData: debounce(function() {
            var g = this;
            QuilityAPI.getSearchSupportResults(null, {
                search: this.keywords,
                itemsPerPage: 4,
                page: 1,
            }).then(function(json) {
                g.search_results = json;
            });
            this.firstPull = false;
        }, 500),
        performSearch: function(e) {
            if (e.keyCode === 13) {
                this.searchResults();
            }
            if (this.keywords == null || this.keywords == "") {
                //this.show_results = false
            } else {
                this.loadData();
            }
        },
        searchResults: function() {
            this.active = false;
            this.$store.commit("updateShowSearch", false);
            this.$router
                .push({ name: "SearchResults", query: { keyword: this.keywords } })
                .catch((err) => {
                    g.showError("Whoops! " + err);
                });
        },
    },
    watch: {
        search_support: function(newV) {
            if (newV) {
                SearchResult.create({ data: {} });
                this.keywords = "";
            }
        },
    },
    components: {},
};
</script>